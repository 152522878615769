export default () => {
  /**
   * Datalayer item.
   *
   * @param {Object} data
   * @param {Integer} quantity
   * @returns
   */
  const item = ({ product, index = 0, data = {} }, quantity = 1) => {
    const categories = {};
    product.categories?.forEach((category, categoryIndex) => {
      categories[`item_category${categoryIndex > 0 ? categoryIndex + 1 : ''}`] = category.title;
    });

    return {
      item_id: product.variants[0].sku,
      item_name: product.title,
      affiliation: 'Kötter Living webshop',
      currency: data.currency || 'EUR',
      discount: product.variants[0].price - product.variants[0].salePrice,
      index,
      item_brand: product.brand?.length ? product.brand[0].title : '',
      item_list_id: 'products',
      item_list_name: 'Collection',
      item_variant: product.variants[0].title,
      price: product.variants[0].price,
      quantity,
      ...categories,
      ...data,
    };
  };

  return { item };
};
